<template>
    <div class="main">
        <div class="container">
            <div class="title">
                <!--<div class="links float-right">-->
                    <!--<a href="" @click.prevent="updateCibList" class="btn btn-sm" style="margin-top: 4px;">Update data on server</a>-->
                <!--</div>-->
                <h1>CIB</h1>
            </div>
            <table class="table">
                <thead>
                <tr>
                    <td width="90%">Name</td>
                    <td>State</td>
                </tr>
                </thead>
                <tbody>
                    <tr v-for="reason in reasons" @click="checkReason(reason)" style="cursor: pointer;">
                        <td>{{ reason.name }}</td>
                        <td>
                            <div class="form-check" style="padding-left: 0">
                                <label class="custom-control custom-checkbox form-check-label" :for="`id-${reason.id}`">
                                    <input
                                        v-model="reason.state"
                                        type="checkbox"
                                        :id="`id-${reason.id}`"
                                        class="custom-control-input form-check-input mr-2"
                                    />
                                    <span class="custom-control-indicator"></span>
                                    <span style="font-weight: 600;font-size: 14px;margin-top: 2px;display: inline-block;">{{ reason.state ? 'On' : 'Off' }}</span>
                                </label>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
	export default {
		name: "InvoicesGenerationCib",
        data() {
			return {
				reasons: []
            }
        },
        mounted() {
            this.updateCibList()
        },
        methods: {
	        checkReason(reason) {
	        	let params = {
			        state: !reason.state
                }
		        newbillinkAxios
			        .patch('/cib/closing-reasons/' + reason.id, params)
			        .then(({data}) => {
				        reason.state = !reason.state
			        })
			        .catch(err => console.error(err))
            },
	        updateCibList() {
		        newbillinkAxios
			        .post('/cib/closing-reasons/update-list')
			        .then(({data}) => {
				        this.getCibList()
			        })
			        .catch(err => console.error(err))
            },
	        getCibList() {
	        	newbillinkAxios
                    .get('/cib/closing-reasons/list')
                    .then(({data}) => {
                    	this.reasons = [...data]
                    })
                    .catch(err => console.error(err))
            }
        }
	}
</script>

<style scoped>

</style>